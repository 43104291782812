import { serializeError } from 'eth-rpc-errors'
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { SerializedEthereumRpcError } from 'eth-rpc-errors/dist/classes'

const jsonError: any = {
  xPP: 'Only permited by authorized roxPerpRouter',
  xMgr: 'Only permited by authorized roxPosionManager4',
  AI: 'Pool not initialized',
  t6: 'tick spacing must be 600',
  M0: 'Token0 transferred in not enough',
  M1: 'Token1 transferred in not enough',
  '0bn': 'Liquidity(Token0) is being utilized by perpetual trading.',
  '1bn': 'Liquidity(Token1) is being utilized by perpetual trading.',
  AS: 'swap amount is 0',
  SPL: 'The limit price set by the user exceeds the normal range.',
  IS: ' The liquidity in spot pool is 0',
  a0: 'system error',
  a1: 'system error',
  IIA: 'system error',
  t0s: 'Liquidity(Token0) is being utilized by perpetual trading.',
  t1s: 'Liquidity(Token1) is being utilized by perpetual trading.',
  uf: ' PerpPosition accumulated fee is larger than collateral',
  sd: 'Only permited by authorized roxRouter',
  ds: 'Size delta is larger than position size, please decrease size delta',
  dc: 'Collateral delta is larger than position collateral, please decrease collateral delta',
  // dC: 'Position should be liquidated due to fees and losses exceeding the principal',
  '<c': 'Collateral delta is larger than position collateral, please decrease collateral delta',
  t0p: 'Due to excessive utilization of liquidity in perpetual trading, it is not possible to add to the position. (Token0)',
  t1p: 'Due to excessive utilization of liquidity in perpetual trading, it is not possible to add to the position. (Token1)',
  '-0': 'Perp Reserve0 is smaller than reserve delta',
  '-1': 'Perp Reserve1 is smaller than reserve delta',
  b0: 'Token0 transfer in perpPool is not enougth',
  b1: 'Token1 transfer in perpPool is not enougth',
  nRv: 'perp settle reserve calculation error',
  lRv: 'perp settle reserve calculation error',
  c: 'liquidity for perp settle is not enough',
  tp: 'token amount should larger than profit',
  xPp: 'not authorized to update tick price',
  out: "of liq burn	The decreased liquidity is greater than the user's specified liquidity value.",
  ol: 'not authorized to set',
  zero: 'amount in	specified token amount should larger than 0',
  'notLiq.': 'The liquidation condition has not been met.',
  NP0: 'The take-profit condition has not been reached.(0)',
  NP1: 'The take-profit condition has not been reached.(1)',
  // "not in pos": "The position is not in the take-profit queue.",
}

export const handlerError = (error: any) => {
  const rpcError: SerializedEthereumRpcError = serializeError(error)
  // @ts-ignore
  if (rpcError.data || rpcError.data.originalError) {
    const errs = rpcError.data as any
    const msgError = errs.originalError.reason
    if (msgError == 'execution reverted: err spread') {
      return 'Transaction Failed: Price Spread Exceeds MaxSlippage Setting.'
    }
    const temperr: string = msgError.replace('execution reverted:', '')
    if (jsonError[temperr]) return jsonError[temperr]

    return msgError
  } else {
    return rpcError.message
  }
}
