import { Trans } from '@lingui/macro'
import Column from 'components/Column'
import Row from 'components/Row'
import dayjs from 'dayjs'
import { useEthPriceUSD, useRoxPriceETH } from 'hooks/useQueryLiquidity'
import { useMemo } from 'react'
import { useVoteCurrentId } from 'state/http/hooks'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { getEndRound, getEpochNum } from 'utils/CurrRound'
import { formatAmount, formatAmount2 } from 'utils/formatAmout'

import { useFetchVoter, useTotalVoteds } from './hooks'
import { VoteDataProps } from './types'

const VoteInfoBox = styled(Row)`
  justify-content: space-between;
  padding: 22px 0 30px;
  border-bottom: 1px solid ${({ theme }) => theme.primaryBorder};
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.xl}px`}) {
    padding: 16px 8px 16px;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export default function VoteInfo() {
  const totalVoted = useTotalVoteds()
  const time = getEndRound()
  const currEpoch = useVoteCurrentId()
  const day = dayjs.unix(time).format('YYYY/MM/DD')
  const ethprice = useEthPriceUSD()
  const roxprice = useRoxPriceETH()

  const voteList = useFetchVoter()

  const totalReward = useMemo(() => {
    if (voteList?.voteList?.length == 0) return 0
    let totalVolume = 0
    voteList?.voteList?.map((leaderboard: VoteDataProps) => {
      totalVolume += Number(leaderboard.votetotalRewardsUSD)
    })
    return totalVolume
  }, [voteList.voteList])

  const averageApr = useMemo(() => {
    if (Number(ethprice) == 0 || Number(roxprice) == 0 || Number(totalVoted) == 0) return 0
    return (totalReward * 365) / 7 / ((Number(totalVoted) / Math.pow(10, 18)) * Number(ethprice) * Number(roxprice))
  }, [ethprice, roxprice, totalReward, totalVoted])
  return (
    <VoteInfoBox>
      <Column align="center">
        <ThemedText.TextSecondary fontSize={14}>
          <Trans>Total Rewards</Trans>
        </ThemedText.TextSecondary>
        <ThemedText.TextPrimary fontSize={18} fontWeight={700} mt="12px">
          ${formatAmount(totalReward, 2, true) || '0'}
        </ThemedText.TextPrimary>
      </Column>
      <Column align="center">
        <ThemedText.TextSecondary fontSize={14}>
          <Trans>Average Voting APR</Trans>
        </ThemedText.TextSecondary>
        <ThemedText.TextPrimary fontSize={18} fontWeight={700} mt="12px">
          {formatAmount(Number(averageApr) * 100, 2, true)}%
        </ThemedText.TextPrimary>
      </Column>
      <Column align="center">
        <ThemedText.TextSecondary fontSize={14}>
          <Trans>Total votes</Trans>
        </ThemedText.TextSecondary>
        <ThemedText.TextPrimary fontSize={18} fontWeight={700} mt="12px">
          {formatAmount2(totalVoted, 18, 2, true) || '0'} veROX
        </ThemedText.TextPrimary>
      </Column>
      <Column align="center">
        <ThemedText.TextSecondary fontSize={14}>
          <Trans>Epoch {getEpochNum(currEpoch)} Ends in</Trans>
        </ThemedText.TextSecondary>
        <ThemedText.TextPrimary fontSize={18} fontWeight={700} mt="12px">
          {day}
        </ThemedText.TextPrimary>
      </Column>
    </VoteInfoBox>
  )
}
