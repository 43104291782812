import { useMediaQuery } from '@mui/material'
import { useMemo } from 'react'
import { BREAKPOINTS } from 'theme'
import { fromWei } from 'utils/bn'

import { useALDandLiqData } from '../hooks'
import Table from './AdlTable'
import TableM from './AdlTable-m'

export function AdlList({ token01 }: { token01: boolean }) {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.lg}px)`)
  const data = useALDandLiqData()
  const resData = useMemo(() => {
    if (!data) return null
    const arr1Sort =
      data
        ?.filter((x) => x.hasProfit)
        ?.sort((a, b) => {
          const feesdecimalsA = a.long0 ? a.v3Pool.token0.decimals : a.v3Pool.token1.decimals
          const feesdecimalsb = b.long0 ? b.v3Pool.token0.decimals : b.v3Pool.token1.decimals
          return fromWei(a.profitDelta, feesdecimalsA).toNumber() - fromWei(b.profitDelta, feesdecimalsb).toNumber()
        }) || null

    const arr2Sort =
      data
        ?.filter((x) => !x.hasProfit)
        ?.sort((a, b) => {
          const feesdecimalsA = a.long0 ? a.v3Pool.token0.decimals : a.v3Pool.token1.decimals
          const feesdecimalsb = b.long0 ? b.v3Pool.token0.decimals : b.v3Pool.token1.decimals
          return fromWei(a.profitDelta, feesdecimalsA).toNumber() - fromWei(b.profitDelta, feesdecimalsb).toNumber()
        }) || null

    return [...arr1Sort, ...arr2Sort]
  }, [data])
  return (
    <>
      {isSmallScreen ? <TableM token01={token01} resData={resData} /> : <Table token01={token01} resData={resData} />}
    </>
  )
}
