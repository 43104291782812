import { Trans } from '@lingui/macro'
import { ReactComponent as LongIcon } from 'assets/svg/swap/long.svg'
import { ReactComponent as ShortIcon } from 'assets/svg/swap/short.svg'
import Column, { AutoColumn } from 'components/Column'
import {
  StyledPositionCloseIcon,
  StyledPositionEditIcon,
  StyledPositionEntryIcon,
  StyledPositionLiqIcon,
  StyledPriceChangeIcon,
} from 'components/Icons/StyledIcon'
import { IconLoadingBubble, LoadingBubble, MediumLoadingBubble } from 'components/Loading'
import Row, { RowBetween } from 'components/Row'
import { useCurrency } from 'hooks/Tokens'
import { StyledTableRowM, TextLeftCell, TextRightCell } from 'pages/Liquidity/LiquidityRow-m'
import { usePositonDetail } from 'pages/Trade/Hooks'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useMemo, useState } from 'react'
import { useTradeSWAPState } from 'state/positionSwap/hooks'
import { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { BN, formFeeWei, fromSqrt96Wei, fromWei } from 'utils/bn'
import { countZeros } from 'utils/countZeros'

import { TextPrimarySpace } from '../styles'
import { PositionMarkPrice } from './PositionMarkPrice'
import { PositionNetValue } from './PositionNetValue'
import { PositionSize } from './PositionSize'
import { TokenSortMethod } from './state'
import { LoadedPositionRowProps } from './TableRow'

/* Token Row: skeleton row component */
function PositionRow({
  Position,
  Size,
  NetValue,
  // Collateral,
  MarkPrice,
  EntryPrice,
  // LiqPrice,
  Actitons,
  ...rest
}: {
  first?: boolean
  $loading?: boolean
  Position: ReactNode
  Size: ReactNode
  NetValue: ReactNode
  // Collateral: ReactNode
  MarkPrice: ReactNode
  EntryPrice: ReactNode
  // LiqPrice: ReactNode
  Actitons: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowCells = (
    <>
      <RowBetween align="flex-start" gap="sm">
        <TextLeftCell data-testid="pool-cell">{Position}</TextLeftCell>
        <TextRightCell data-testid="size-cell">{Size}</TextRightCell>
      </RowBetween>
      <RowBetween align="flex-start" gap="sm">
        <TextLeftCell data-testid="netValue-cell">{NetValue}</TextLeftCell>
        <TextRightCell data-testid="markPrice-cell">{MarkPrice}</TextRightCell>
      </RowBetween>
      <RowBetween align="center" gap="sm" alignItems="center">
        <TextLeftCell data-testid="entryPrice-cell">{EntryPrice} </TextLeftCell>
        {/* <TextRightCell data-testid="liqPrice-cell">{LiqPrice}</TextRightCell> */}
        <TextRightCell data-testid="liqPrice-cell">{Actitons}</TextRightCell>
      </RowBetween>
      {/* <Box display="flex" justifyContent="flex-end">
        <TextRightCell data-testid="actions-cell">{Actitons}</TextRightCell>
      </Box> */}
    </>
  )
  return <StyledTableRowM {...rest}>{rowCells}</StyledTableRowM>
}

/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <PositionRow
      $loading
      Position={
        <Column align="flex-start" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{TokenSortMethod.Position}</Trans>
          </ThemedText.TextSecondary>
          <Row gap="sm">
            <IconLoadingBubble width="30px" height="30px" />
            <MediumLoadingBubble />
          </Row>
        </Column>
      }
      Size={
        <Column align="flex-end" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{TokenSortMethod.Size}</Trans>
          </ThemedText.TextSecondary>
          <MediumLoadingBubble />
        </Column>
      }
      NetValue={
        <Column align="flex-start" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{TokenSortMethod.NetValue}</Trans>
          </ThemedText.TextSecondary>
          <LoadingBubble />
        </Column>
      }
      // Collateral={
      //   <Column align="flex-end" width="100%" gap="xs">
      //     <ThemedText.TextSecondary fontSize={12}>
      //       <Trans>{TokenSortMethod.Collateral}</Trans>
      //     </ThemedText.TextSecondary>
      //     <MediumLoadingBubble />
      //   </Column>
      // }
      MarkPrice={
        <Column align="flex-end" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{TokenSortMethod.MarkPrice}</Trans>
          </ThemedText.TextSecondary>
          <MediumLoadingBubble />
        </Column>
      }
      EntryPrice={
        <Column align="flex-start" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{TokenSortMethod.EntryPrice}</Trans>
          </ThemedText.TextSecondary>
          <MediumLoadingBubble />
        </Column>
      }
      // LiqPrice={
      //   <Column align="flex-end" width="100%" gap="xs">
      //     <ThemedText.TextSecondary fontSize={12}>
      //       <Trans>{TokenSortMethod.LiqPrice}</Trans>
      //     </ThemedText.TextSecondary>
      //     <MediumLoadingBubble />
      //   </Column>
      // }
      Actitons={
        <Column align="flex-end" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{TokenSortMethod.Operation}</Trans>
          </ThemedText.TextSecondary>
          <MediumLoadingBubble />
        </Column>
      }
      {...props}
    />
  )
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedPositionRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { positionListIndex, positionListLength, position, closePosition, editPosition } = props
  const theme = useTheme()
  const token0 = useCurrency(position?.token0)
  const token1 = useCurrency(position?.token1)
  const [showLiqPirce, setShowLiqPrice] = useState(false)
  const { isToken0 } = useTradeSWAPState()

  const { currentSqrtRatioX96, traderFee, symbol } = usePositonDetail(position)

  const isLong = position.long0 ? token1?.symbol : token0?.symbol
  const decimals = position.long0 ? token1?.decimals : token0?.decimals

  const initialCollateral = useMemo(() => {
    if (!position || !token0 || !token1 || !decimals) return
    return fromWei(position.collateral.toString(), decimals).toString()
  }, [decimals, position, token0, token1])

  const markPrice = useMemo(() => {
    if (!currentSqrtRatioX96) return
    const cur = fromSqrt96Wei(currentSqrtRatioX96.toString(), token0?.decimals, token1?.decimals)

    if (!isToken0) {
      return BN(1).div(cur).toFixed()
    }
    return cur.toFixed()
  }, [currentSqrtRatioX96, isToken0, token0?.decimals, token1?.decimals])

  const EntryPrice = useMemo(() => {
    if (!position) return
    const cur = fromSqrt96Wei(position.sqrtPriceX96.toString(), token0?.decimals, token1?.decimals)

    if (!isToken0) {
      return BN(1).div(cur).toFixed()
    }
    return cur.toFixed()
  }, [isToken0, position, token0?.decimals, token1?.decimals])

  const Openpnl = useMemo(() => {
    if (!markPrice || !EntryPrice) return null
    if (position.long0) {
      return BN(markPrice)
        .minus(EntryPrice)
        .dividedBy(EntryPrice)
        .multipliedBy(fromWei(position.size.toString(), decimals).toString())
        .toString()
    } else {
      return BN(EntryPrice)
        .minus(markPrice)
        .dividedBy(markPrice)
        .multipliedBy(fromWei(position.size.toString(), decimals).toString())
        .toString()
    }
  }, [EntryPrice, decimals, markPrice, position.long0, position.size])

  const netValueFees = useMemo(() => {
    if (!traderFee || !position || !decimals || !initialCollateral || !Openpnl) return
    const positionFeeint = formFeeWei(traderFee.positionFee)

    const formsize = fromWei(position.size.toString(), decimals)
    const formcollateral = BN(initialCollateral)

    const pnl = Openpnl
    const positionFee = formsize.times(positionFeeint)

    const borrowFee = fromWei(position.fundingFee.toString(), decimals)

    const allFee = positionFee.times(2).plus(borrowFee).toFixed()

    // netvalue
    const currentCollateral = formcollateral.minus(positionFee).toFixed()

    const PnLAfterFees = BN(pnl).minus(allFee).toString()

    const PnLAfterFeesString = BN(pnl).minus(allFee).gt(0) ? `+${PnLAfterFees}` : PnLAfterFees

    const AfterHasProfiet = BN(PnLAfterFees).gt(BN(0))

    const currentvalue = formcollateral.plus(BN(pnl).minus(allFee)).toFixed()

    const rationPNLvalue = BN(pnl).minus(allFee).div(formcollateral).gt(0)
      ? `+${BN(pnl).minus(allFee).div(formcollateral).times(100).toFixed(2)}`
      : BN(pnl).minus(allFee).div(formcollateral).times(100).toFixed(2)

    return {
      borrowFee: borrowFee.toFixed(),
      closeSpread: '0',
      allFee,
      PnL: BN(pnl).gt(0) ? `+${pnl}` : pnl,
      Positionfees: positionFee.toFixed(),
      netValue: currentvalue,
      currentCollateral,
      PnLAfterFees: PnLAfterFeesString,
      AfterHasProfiet,
      ratioPnL: rationPNLvalue,
    }
  }, [Openpnl, decimals, initialCollateral, position, traderFee])
  const LiqPrice = useMemo(() => {
    if (!position) return
    const cur = fromSqrt96Wei(position.liqSqrtPriceX96.toString(), token0?.decimals, token1?.decimals)

    if (!isToken0) {
      return BN(1).div(cur).toFixed()
    }
    return cur.toFixed()
  }, [isToken0, position, token0?.decimals, token1?.decimals])

  const positionLong0 = useMemo(() => {
    if (position.long0) {
      if (isToken0) {
        return {
          long0: true,
          size: 'L',
        }
      } else {
        return {
          long0: false,
          size: 'S',
        }
      }
    } else {
      if (isToken0) {
        return {
          long0: false,
          size: 'S',
        }
      } else {
        return {
          long0: true,
          size: 'L',
        }
      }
    }
  }, [isToken0, position.long0])
  return (
    <div ref={ref} data-testid={`position-table-row-${position.account}`}>
      <PositionRow
        Position={
          <Column align="flex-start" width="100%" gap="xs">
            <ThemedText.TextSecondary fontSize={12}>
              <Trans>{TokenSortMethod.Position}</Trans>
            </ThemedText.TextSecondary>
            <AutoColumn>
              <ThemedText.TextPrimary style={TextPrimarySpace} fontWeight={700} fontSize={16}>
                {symbol}
              </ThemedText.TextPrimary>
              <Row gap="xs">
                <ThemedText.TextPrimary fontWeight={400} fontSize={14}>
                  {BN(position.size.toString()).div(position.collateral.toString()).toFixed(2)}x
                </ThemedText.TextPrimary>
                {positionLong0.long0 ? <LongIcon /> : <ShortIcon />}
              </Row>
            </AutoColumn>
          </Column>
        }
        Size={
          <Column align="flex-end" width="100%" gap="xs">
            <ThemedText.TextSecondary fontSize={12}>
              <Trans>{TokenSortMethod.Size}</Trans>
            </ThemedText.TextSecondary>
            <PositionSize position={position} />
          </Column>
        }
        NetValue={
          <Column align="flex-start" width="100%" gap="xs">
            <ThemedText.TextSecondary fontSize={12}>
              <Trans>{TokenSortMethod.NetValue}</Trans>
            </ThemedText.TextSecondary>
            <PositionNetValue initialCollateral={initialCollateral} token={isLong} netValueFees={netValueFees} />
            <Row gap="xs" align="center">
              <ThemedText.BodySmall
                color={netValueFees?.AfterHasProfiet ? 'long' : 'tradeRed'}
                fontWeight={400}
                fontSize={12}
              >
                {countZeros(netValueFees?.PnLAfterFees)} ({netValueFees?.ratioPnL}%)
              </ThemedText.BodySmall>
            </Row>
          </Column>
        }
        // Collateral={
        //   <Column align="flex-end" width="100%" gap="xs">
        //     <ThemedText.TextSecondary fontSize={12}>
        //       <Trans>{TokenSortMethod.Collateral}</Trans>
        //     </ThemedText.TextSecondary>
        //     <PositionCollateral initialCollateral={initialCollateral} netValueFees={netValueFees} token={isLong} />
        //   </Column>
        // }
        MarkPrice={
          <Column align="flex-end" width="100%" gap="xs">
            <ThemedText.TextSecondary fontSize={12}>
              <Trans>{TokenSortMethod.MarkPrice}</Trans>
            </ThemedText.TextSecondary>
            <PositionMarkPrice currentSqrtRatioX96={markPrice} />
          </Column>
        }
        EntryPrice={
          <Column align="flex-start" width="100%" gap="xs">
            <ThemedText.TextSecondary fontSize={12}>
              <Trans>
                {' '}
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setShowLiqPrice(!showLiqPirce)
                  }}
                >
                  {!showLiqPirce ? TokenSortMethod.EntryPrice : TokenSortMethod.LiqPrice}
                  <StyledPriceChangeIcon style={{ marginLeft: '4px' }} />
                </div>
              </Trans>
            </ThemedText.TextSecondary>
            <ThemedText.TextPrimary fontWeight={700} fontSize={12} display="flex">
              {!showLiqPirce ? (
                <>
                  <StyledPositionEntryIcon color={theme.accentActive} opacity={1} style={{ marginRight: '4px' }} />
                  {countZeros(EntryPrice)}
                </>
              ) : (
                <>
                  <StyledPositionLiqIcon style={{ marginRight: '4px' }} /> {countZeros(LiqPrice)}
                </>
              )}
            </ThemedText.TextPrimary>
          </Column>
        }
        // LiqPrice={
        //   <Column align="flex-end" width="100%" gap="xs">
        //     <ThemedText.TextSecondary fontSize={12}>
        //       <Trans>{TokenSortMethod.LiqPrice}</Trans>
        //     </ThemedText.TextSecondary>
        //     <ThemedText.TextPrimary fontWeight={700} fontSize={12}>
        //       {countZeros(LiqPrice)}
        //     </ThemedText.TextPrimary>
        //   </Column>
        // }
        Actitons={
          <Row justify="flex-end" gap="xmd">
            <ThemedText.TextPrimary
              display="flex"
              alignItems="center"
              style={{ cursor: 'pointer' }}
              onClick={() => editPosition(position) }
            >
              <StyledPositionEditIcon viewBox="12px 12px" />
            </ThemedText.TextPrimary>
            <ThemedText.TextPrimary
              display="flex"
              alignItems="center"
              style={{ cursor: 'pointer' }}
              onClick={() => closePosition(position)}
            >
              <StyledPositionCloseIcon viewBox="12px 12px" />
            </ThemedText.TextPrimary>
          </Row>
        }
        first={positionListIndex === 0}
        last={positionListIndex === positionListLength - 1}
      />
    </div>
  )
})

LoadedRow.displayName = 'LoadedRow'
