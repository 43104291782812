import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { Percent } from '@uniswap/sdk-core'
import { AddButton, ClaimButton, Pending } from 'components/Button'
import Column from 'components/Column'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { IconLoadingBubble, LongLoadingBubble, SmallLoadingBubble } from 'components/Loading'
import Row, { RowBetween } from 'components/Row'
import { Cell, StyledTableRow } from 'components/Table'
import { ToastError } from 'components/Toast'
import Tooltip from 'components/Tooltip'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useCurrency, useToken } from 'hooks/Tokens'
import { useMasterChefV3 } from 'hooks/useContract'
import { TotalRewardsItem } from 'pages/Vote/VoteRow'
import { CSSProperties, ForwardedRef, forwardRef, ReactNode, useState } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled, { css } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { formatAmount } from 'utils/formatAmout'
import { formatCurrencyAmount, NumberType } from 'utils/formatCurrency'
import { handlerError } from 'utils/formatError'
import { unwrappedToken } from 'utils/unwrappedToken'

import { ReactComponent as AddIcon } from '../../assets/imgs/add.svg'
import { ReactComponent as ToIcon } from '../../assets/imgs/to2.svg'
import FarmPoolModal from './FarmPoolModal'
import { useSharsAmount } from './FarmPoolModal/Hooks'
import { LoadedRowProps, TooltipBox, TooltipRow, TotalLiquidityEarnsItem } from './LiquidityRow'
import { LiquiditySortMethod } from './state'

export const StyledTableRowM = styled(StyledTableRow)<{
  first?: boolean
  last?: boolean
  $loading?: boolean
}>`
  width: 100%;
  padding: 16px 8px;
  margin: 0;
  gap: 16px;
  ${({ first, last }) => css`
    /* height: ${first || last ? '72px' : '64px'}; */
  `}
  transition: ${({
    theme: {
      transition: { duration, timing },
    },
  }) => css`background-color ${duration.medium} ${timing.ease}`};
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  &:last-child {
    border: none;
  }

  &:hover {
    ${({ $loading, theme }) =>
      !$loading &&
      css`
        background-color: ${theme.hoverDefault};
      `}
    ${({ last }) =>
      last &&
      css`
        border-radius: 0px 0px 8px 8px;
      `}
  }
`

export const TextLeftCell = styled(Cell)`
  justify-content: flex-start;
  flex: 1;
`

export const TextRightCell = styled(Cell)`
  justify-content: flex-end;
  text-align: right;
  flex: 1;
`

/* Token Row: skeleton row component */
function LiquidityRow({
  pools,
  tvl,
  apr,
  rewards,
  my_staked,
  earn,
  ...rest
}: {
  first?: boolean
  $loading?: boolean
  rewards: ReactNode
  tvl: ReactNode
  apr: ReactNode
  pools: ReactNode
  my_staked: ReactNode
  earn: ReactNode
  last?: boolean
  style?: CSSProperties
}) {
  const rowCells = (
    <>
      <TextLeftCell data-testid="pool-cell">{pools}</TextLeftCell>
      <RowBetween gap="sm" align="flex-start">
        <TextLeftCell data-testid="tvl-cell">{tvl}</TextLeftCell>
        <TextRightCell data-testid="apr-cell">{apr}</TextRightCell>
        <TextRightCell data-testid="rewards-cell">{rewards}</TextRightCell>
      </RowBetween>
      <RowBetween gap="sm" align="flex-start">
        <TextLeftCell data-testid="my_staked-cell" className="">
          {my_staked}
        </TextLeftCell>
        <TextRightCell data-testid="my-liquidity-cell" className="">
          {earn}
        </TextRightCell>
      </RowBetween>
    </>
  )
  return <StyledTableRowM {...rest}>{rowCells}</StyledTableRowM>
}

/* Loading State: row component with loading bubbles */
export function LoadingRow(props: { first?: boolean; last?: boolean }) {
  return (
    <LiquidityRow
      $loading
      pools={
        <RowBetween gap="sm">
          <Row gap="sm">
            <IconLoadingBubble width="40px" height="40px" />
            <SmallLoadingBubble />
          </Row>
          <ToIcon />
        </RowBetween>
      }
      tvl={
        <Column align="flex-start" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{LiquiditySortMethod.TVL}</Trans>
          </ThemedText.TextSecondary>
          <LongLoadingBubble />
        </Column>
      }
      apr={
        <Column align="flex-end" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{LiquiditySortMethod.APR}</Trans>
          </ThemedText.TextSecondary>
          <LongLoadingBubble />
        </Column>
      }
      rewards={
        <Column align="flex-end" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{LiquiditySortMethod.REWARDS}</Trans>
          </ThemedText.TextSecondary>
          <LongLoadingBubble />
        </Column>
      }
      my_staked={
        <Column align="flex-start" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{LiquiditySortMethod.MY_STAKED}</Trans>
          </ThemedText.TextSecondary>
          <SmallLoadingBubble />
          <Box mt="6px">
            <AddButton>+/—</AddButton>
          </Box>
        </Column>
      }
      earn={
        <Column align="flex-end" width="100%" gap="xs">
          <ThemedText.TextSecondary fontSize={12}>
            <Trans>{LiquiditySortMethod.EARN}</Trans>
          </ThemedText.TextSecondary>
          <SmallLoadingBubble />
          <ClaimButton>Claim</ClaimButton>
        </Column>
      }
      {...props}
    />
  )
}

/* Loaded State: row component with token information */
export const LoadedRow = forwardRef((props: LoadedRowProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { liquidityListIndex, liquidityListLength, liquidity, addBribeChage, showPoolMidalChage } = props

  const [showHover_TVL, setShowHover_TVL] = useState(false)
  const [showHover_rewards, setShowHover_rewards] = useState(false)

  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const pendingText = 'pending ...'
  const addTransaction = useTransactionAdder()

  const [showFarmPool, setShowFarmPool] = useState(false)
  const handleDismissCreate = () => {
    setShowFarmPool(false)
  }

  const masterChefContract = useMasterChefV3(liquidity.masterChefAddress)
  const { account } = useActiveChainId()
  const currrencyA = useCurrency(liquidity.token0.id)
  const currrencyB = useCurrency(liquidity.token1.id)
  const sharedAmount = useSharsAmount(liquidity.hypervisorAddress, currrencyA ?? undefined, currrencyB ?? undefined)

  const handleClaim = async () => {
    setAttemptingTxn(true)
    if (!masterChefContract || !liquidity || !account) return
    try {
      const tempList = liquidity.LiquidityrewardsLists?.map((item) => item?.tokenInfo?.id)
      if (!tempList) return
      const response = await masterChefContract.getReward(account)
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.CLAIM_REWARDS,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      ToastError(handlerError(error))
      console.log('masterChefContract claimRewards error', error)
    }
  }

  const liquidityToken0 = useToken(liquidity.token0.id)
  const liquidityToken1 = useToken(liquidity.token1.id)
  const currency0 = liquidityToken0 ? unwrappedToken(liquidityToken0) : undefined
  const currency1 = liquidityToken1 ? unwrappedToken(liquidityToken1) : undefined

  return (
    <>
      <div ref={ref} data-testid={`liquidity-table-row-${liquidity.lpAddr}`}>
        <LiquidityRow
          pools={
            <RowBetween gap="sm">
              <Row gap="sm">
                <DoubleCurrencyLogo currency0={currency1} currency1={currency0} size={40} margin />
                <Column gap="xs" align="flex-start" ml="8px">
                  <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                    {liquidity.token0.symbol}-{liquidity.token1.symbol}
                  </ThemedText.TextPrimary>
                  <ThemedText.TextPrimary fontWeight={700} fontSize={16}>
                    {new Percent(liquidity.poolFee, 1_000_000).toSignificant()}%
                  </ThemedText.TextPrimary>
                </Column>
              </Row>
              <ToIcon />
            </RowBetween>
          }
          tvl={
            <Column align="flex-start" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>{LiquiditySortMethod.TVL}</Trans>
              </ThemedText.TextSecondary>
              <Column align="start" width="100%">
                <Tooltip
                  show={showHover_TVL}
                  text={
                    <TooltipBox>
                      <Row justify="space-between" alignItems="flex-start" className="tooltipItem">
                        {/* <ThemedText.TextPrimary fontWeight={700} className="text">
                          <Trans>Wallet</Trans>
                        </ThemedText.TextPrimary> */}
                        <ThemedText.TextSecondary fontSize={14}>
                          {formatCurrencyAmount(liquidity?.liqvidityToken0, NumberType.FiatTokenStats2)}{' '}
                          {liquidity.token0.symbol}
                        </ThemedText.TextSecondary>
                      </Row>
                      <Row justify="space-between" alignItems="flex-start" className="tooltipItem">
                        {/* <ThemedText.TextPrimary fontWeight={700} className="text">
                          <Trans>Staked</Trans>
                        </ThemedText.TextPrimary> */}
                        <ThemedText.TextSecondary fontSize={14}>
                          {formatCurrencyAmount(liquidity.liqvidityToken1, NumberType.FiatTokenStats2)}{' '}
                          {liquidity.token1.symbol}
                        </ThemedText.TextSecondary>
                      </Row>
                    </TooltipBox>
                  }
                >
                  <TooltipRow onMouseEnter={() => setShowHover_TVL(true)} onMouseLeave={() => setShowHover_TVL(false)}>
                    <ThemedText.TextPrimary fontWeight={700}>
                      ${formatAmount(liquidity.tvl, 2, true)}
                    </ThemedText.TextPrimary>
                  </TooltipRow>
                </Tooltip>
              </Column>
            </Column>
          }
          apr={
            <Column align="flex-end" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>{LiquiditySortMethod.APR}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={700}>
                {formatAmount(Number(liquidity.liquityAPR) * 100, 2, true)}%
              </ThemedText.TextPrimary>
            </Column>
          }
          rewards={
            <Column align="flex-end" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>{LiquiditySortMethod.REWARDS}</Trans>
              </ThemedText.TextSecondary>
              <Column align="end" width="100%">
                <ThemedText.TextPrimary fontWeight={700}>
                  ${formatAmount(liquidity.LiquidityrewardUSD, 2, true)}
                </ThemedText.TextPrimary>
                {liquidity?.LiquidityrewardsLists?.map((item, index) => {
                  return <TotalRewardsItem key={'liquidity-rewards-item' + index} rewardAddr={item}></TotalRewardsItem>
                })}
                <AddIcon style={{ cursor: 'pointer' }} onClick={() => addBribeChage(liquidity)} />
              </Column>
            </Column>
          }
          my_staked={
            <Column align="flex-start" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>{LiquiditySortMethod.MY_STAKED}</Trans>
              </ThemedText.TextSecondary>
              <ThemedText.TextPrimary fontWeight={700}>
                ${formatAmount(liquidity.stakeData.tvl, 2, true)}
              </ThemedText.TextPrimary>
              <ThemedText.TextSecondary fontSize={14}>
                {formatCurrencyAmount(sharedAmount?.amount0, NumberType.FiatTokenStats2)} {liquidity.token0.symbol}
              </ThemedText.TextSecondary>
              <ThemedText.TextSecondary fontSize={14}>
                {formatCurrencyAmount(sharedAmount?.amount1, NumberType.FiatTokenStats2)} {liquidity.token1.symbol}
              </ThemedText.TextSecondary>
              <Box mt="6px">
                <AddButton onClick={() => setShowFarmPool(true)}>+/—</AddButton>
              </Box>
            </Column>
          }
          earn={
            <Column align="flex-end" width="100%" gap="xs">
              <ThemedText.TextSecondary fontSize={12}>
                <Trans>{LiquiditySortMethod.EARN}</Trans>
              </ThemedText.TextSecondary>
              {liquidity.LiquidityrewardsLists && liquidity.LiquidityrewardsLists?.length > 0 ? (
                liquidity.LiquidityrewardsLists?.map((item: any, index: number) => {
                  return (
                    <TotalLiquidityEarnsItem
                      key={'earn-liquidity' + index}
                      rewardAddr={item}
                      masterchefAddr={liquidity.masterChefAddress}
                    />
                  )
                })
              ) : (
                <ThemedText.TextRewards>--</ThemedText.TextRewards>
              )}
              <ClaimButton disabled={attemptingTxn} onClick={handleClaim}>
                {attemptingTxn ? <Pending /> : 'Claim'}
              </ClaimButton>
            </Column>
          }
          first={liquidityListIndex === 0}
          last={liquidityListIndex === liquidityListLength - 1}
        />
      </div>
      <FarmPoolModal
        poolAddress={liquidity.lpAddr}
        token0={liquidity.token0}
        token1={liquidity.token1}
        fee={liquidity.poolFee}
        isOpen={showFarmPool}
        hypervisorAddr={liquidity.hypervisorAddress}
        onDismiss={handleDismissCreate}
      />
    </>
  )
})

LoadedRow.displayName = 'LoadedRow'
