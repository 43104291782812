import { BigNumber } from '@ethersproject/bignumber'
import type { TransactionResponse } from '@ethersproject/providers'
import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { CurrencyAmount, Percent } from '@uniswap/sdk-core'
import { NonfungiblePositionManager } from '@uniswap/v3-sdk'
import { sendEvent } from 'components/analytics'
import { ButtonConfirmed, ButtonPrimary } from 'components/Button'
import { LightCard } from 'components/Card'
import Column, { AutoColumn } from 'components/Column'
import FormattedCurrencyAmount from 'components/FormattedCurrencyAmount'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { AutoRow, RowBetween, RowFixed } from 'components/Row'
import Slider from 'components/Slider'
import Toggle from 'components/Toggle'
import { useActiveChainId } from 'connection/useActiveChainId'
import { WRAPPED_NATIVE_CURRENCY } from 'constants/tokens'
import { useV3NFTPositionManagerContract } from 'hooks/useContract'
import useDebouncedChangeHandler from 'hooks/useDebouncedChangeHandler'
import useTransactionDeadline from 'hooks/useTransactionDeadline'
import { useV3PositionFromTokenId } from 'hooks/useV3Positions'
import useNativeCurrency from 'lib/hooks/useNativeCurrency'
import { ResponsiveHeaderText, SmallMaxButton } from 'pages/RemoveLiquidity/styled'
import { useCallback, useState } from 'react'
import { Text } from 'rebass'
import { useBurnV3ActionHandlers, useBurnV3State, useDerivedV3BurnInfo } from 'state/burn/v3/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { useUserSlippageToleranceWithDefault } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'
import { calculateGasMargin } from 'utils/calculateGasMargin'
import { currencyId } from 'utils/currencyId'
import { handlerError } from 'utils/formatError'
const RemovePoolBox = styled(Box)`
  padding: 16px;
  background-color: ${({ theme }) => theme.backgroundInteractive};
  border-radius: 0px 16px 16px 16px;
`
const Line = styled.div`
  border-bottom: 1px dashed ${({ theme }) => theme.backgroundOutline};
  width: 100%;
`

const AmountBox = styled(LightCard)`
  background: ${({ theme }) => theme.backgroundModule};
`
const RemoveBtn = styled(ButtonConfirmed)`
  background: ${({ theme }) => theme.removeBackground};
  &:hover {
    color: ${({ theme }) => theme.textSecondary};
    background: ${({ theme }) => theme.removeBackground};
    box-shadow: 0px 0px 16px 0px ${({ theme }) => theme.removeBackground};
  }
  &:disabled {
    background: ${({ theme }) => theme.primaryBtn_disabled};
    color: ${({ theme }) => theme.textTertiary};
  }
`
const DEFAULT_REMOVE_V3_LIQUIDITY_SLIPPAGE_TOLERANCE = new Percent(5, 100)
export default function RemovePool({
  tokenId,
  setshowRemovePool,
  setTxHash,
  setAttemptingTxn,
  setTxError,
}: {
  tokenId: number
  setTxHash: any
  setAttemptingTxn: any
  setTxError: any
  setshowRemovePool?: any
}) {
  const { position } = useV3PositionFromTokenId(tokenId ? BigNumber.from(tokenId) : undefined)
  const { account, chainId, provider } = useActiveChainId()

  const theme = useTheme()
  const addTransaction = useTransactionAdder()
  const positionManager = useV3NFTPositionManagerContract()
  // flag for receiving WETH
  const [receiveWETH, setReceiveWETH] = useState(false)
  const nativeCurrency = useNativeCurrency(chainId)
  const nativeWrappedSymbol = nativeCurrency.wrapped.symbol

  const { percent } = useBurnV3State()
  const {
    position: positionSDK,
    liquidityPercentage,
    liquidityValue0,
    liquidityValue1,
    feeValue0,
    feeValue1,
    error,
  } = useDerivedV3BurnInfo(position, receiveWETH)
  const { onPercentSelect } = useBurnV3ActionHandlers()

  // boilerplate for the slider
  const [percentForSlider, onPercentSelectForSlider] = useDebouncedChangeHandler(percent, onPercentSelect)

  const removed = position?.liquidity?.eq(0)

  const showCollectAsWeth = Boolean(
    liquidityValue0?.currency &&
      liquidityValue1?.currency &&
      (liquidityValue0.currency.isNative ||
        liquidityValue1.currency.isNative ||
        WRAPPED_NATIVE_CURRENCY[liquidityValue0.currency.chainId]?.equals(liquidityValue0.currency.wrapped) ||
        WRAPPED_NATIVE_CURRENCY[liquidityValue1.currency.chainId]?.equals(liquidityValue1.currency.wrapped))
  )

  const deadline = useTransactionDeadline() // custom from users settings
  const allowedSlippage = useUserSlippageToleranceWithDefault(DEFAULT_REMOVE_V3_LIQUIDITY_SLIPPAGE_TOLERANCE)

  const burn = useCallback(async () => {
    setAttemptingTxn(true)
    if (
      !positionManager ||
      !liquidityValue0 ||
      !liquidityValue1 ||
      !deadline ||
      !account ||
      !chainId ||
      !positionSDK ||
      !liquidityPercentage ||
      !provider
    ) {
      return
    }
    // we fall back to expecting 0 fees in case the fetch fails, which is safe in the
    // vast majority of cases
    const { calldata, value } = NonfungiblePositionManager.removeCallParameters(positionSDK, {
      tokenId: tokenId.toString(),
      liquidityPercentage,
      slippageTolerance: allowedSlippage,
      deadline: deadline.toString(),
      collectOptions: {
        expectedCurrencyOwed0: feeValue0 ?? CurrencyAmount.fromRawAmount(liquidityValue0.currency, 0),
        expectedCurrencyOwed1: feeValue1 ?? CurrencyAmount.fromRawAmount(liquidityValue1.currency, 0),
        recipient: account,
      },
    })

    const txn = {
      to: positionManager.address,
      data: calldata,
      value,
    }

    provider
      .getSigner()
      .estimateGas(txn)
      .then((estimate) => {
        const newTxn = {
          ...txn,
          gasLimit: calculateGasMargin(estimate),
        }

        return provider
          .getSigner()
          .sendTransaction(newTxn)
          .then((response: TransactionResponse) => {
            sendEvent({
              category: 'Liquidity',
              action: 'RemoveV3',
              label: [liquidityValue0.currency.symbol, liquidityValue1.currency.symbol].join('/'),
            })
            setTxHash(response.hash)
            setAttemptingTxn(false)
            addTransaction(response, {
              type: TransactionType.REMOVE_LIQUIDITY_V3,
              baseCurrencyId: currencyId(liquidityValue0.currency),
              quoteCurrencyId: currencyId(liquidityValue1.currency),
              expectedAmountBaseRaw: liquidityValue0.quotient.toString(),
              expectedAmountQuoteRaw: liquidityValue1.quotient.toString(),
            })
          })
      })
      .catch((error) => {
        setAttemptingTxn(false)
        setTxError(handlerError(error))
        console.error('error', error)
      })
  }, [
    setAttemptingTxn,
    positionManager,
    liquidityValue0,
    liquidityValue1,
    deadline,
    account,
    chainId,
    positionSDK,
    liquidityPercentage,
    provider,
    tokenId,
    allowedSlippage,
    feeValue0,
    feeValue1,
    setTxHash,
    addTransaction,
    setTxError,
  ])

  function modalHeader() {
    return (
      <AutoColumn gap="sm" style={{ padding: '16px' }}>
        <RowBetween align="flex-end">
          <Text fontSize={16} fontWeight={500}>
            <Trans>Pooled {liquidityValue0?.currency?.symbol}:</Trans>
          </Text>
          <RowFixed>
            <Text fontSize={16} fontWeight={500} marginLeft="6px">
              {liquidityValue0 && <FormattedCurrencyAmount currencyAmount={liquidityValue0} />}
            </Text>
            <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={liquidityValue0?.currency} />
          </RowFixed>
        </RowBetween>
        <RowBetween align="flex-end">
          <Text fontSize={16} fontWeight={500}>
            <Trans>Pooled {liquidityValue1?.currency?.symbol}:</Trans>
          </Text>
          <RowFixed>
            <Text fontSize={16} fontWeight={500} marginLeft="6px">
              {liquidityValue1 && <FormattedCurrencyAmount currencyAmount={liquidityValue1} />}
            </Text>
            <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={liquidityValue1?.currency} />
          </RowFixed>
        </RowBetween>
        {feeValue0?.greaterThan(0) || feeValue1?.greaterThan(0) ? (
          <>
            <ThemedText.DeprecatedItalic fontSize={12} color={theme.textSecondary} textAlign="left" padding="8px 0 0 0">
              <Trans>You will also collect fees earned from this position.</Trans>
            </ThemedText.DeprecatedItalic>
            <RowBetween>
              <Text fontSize={16} fontWeight={500}>
                <Trans>{feeValue0?.currency?.symbol} Fees Earned:</Trans>
              </Text>
              <RowFixed>
                <Text fontSize={16} fontWeight={500} marginLeft="6px">
                  {feeValue0 && <FormattedCurrencyAmount currencyAmount={feeValue0} />}
                </Text>
                <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={feeValue0?.currency} />
              </RowFixed>
            </RowBetween>
            <RowBetween>
              <Text fontSize={16} fontWeight={500}>
                <Trans>{feeValue1?.currency?.symbol} Fees Earned:</Trans>
              </Text>
              <RowFixed>
                <Text fontSize={16} fontWeight={500} marginLeft="6px">
                  {feeValue1 && <FormattedCurrencyAmount currencyAmount={feeValue1} />}
                </Text>
                <CurrencyLogo size="20px" style={{ marginLeft: '8px' }} currency={feeValue1?.currency} />
              </RowFixed>
            </RowBetween>
          </>
        ) : null}
        <ButtonPrimary mt="16px" onClick={burn}>
          <Trans>Remove</Trans>
        </ButtonPrimary>
      </AutoColumn>
    )
  }
  return (
    <RemovePoolBox>
      <RowBetween gap="44px" align="flex-start">
        <Column width="100%" align="flex-start" gap="sm">
          <Column width="100%" align="flex-start" gap="sm">
            <ThemedText.TextSecondary>
              <Trans>Pooled</Trans>
            </ThemedText.TextSecondary>
            <RowBetween>
              <CurrencyLogo size="30px" currency={liquidityValue0?.currency} />
              <ThemedText.BodyPrimary fontWeight={700} fontSize={14}>
                {liquidityValue0 ? <FormattedCurrencyAmount currencyAmount={liquidityValue0} /> : '0'}{' '}
                {liquidityValue0?.currency.symbol}
              </ThemedText.BodyPrimary>
            </RowBetween>
            <Line />
            <RowBetween>
              <CurrencyLogo size="30px" currency={liquidityValue1?.currency} />
              <ThemedText.BodyPrimary fontWeight={700} fontSize={14}>
                {liquidityValue1 ? <FormattedCurrencyAmount currencyAmount={liquidityValue1} /> : '0'}{' '}
                {liquidityValue1?.currency.symbol}
              </ThemedText.BodyPrimary>
            </RowBetween>
          </Column>
          <Column width="100%" align="flex-start" gap="sm" mt="8px">
            <ThemedText.TextSecondary>
              <Trans>Fees Earned</Trans>
            </ThemedText.TextSecondary>
            <RowBetween>
              <CurrencyLogo size="30px" currency={feeValue0?.currency} />
              <ThemedText.BodyPrimary fontWeight={700} fontSize={14}>
                {feeValue0 ? <FormattedCurrencyAmount currencyAmount={feeValue0} /> : '0'} {feeValue0?.currency.symbol}
              </ThemedText.BodyPrimary>
            </RowBetween>
            <Line />
            <RowBetween>
              <CurrencyLogo size="30px" currency={feeValue1?.currency} />
              <ThemedText.BodyPrimary fontWeight={700} fontSize={14}>
                {feeValue1 ? <FormattedCurrencyAmount currencyAmount={feeValue1} /> : '0'} {feeValue1?.currency.symbol}
              </ThemedText.BodyPrimary>
            </RowBetween>
          </Column>
        </Column>

        <Column width="100%" align="flex-start" justify="flex-start">
          <AmountBox>
            <AutoColumn gap="md">
              <ThemedText.DeprecatedMain fontWeight={400}>
                <Trans>Amount</Trans>
              </ThemedText.DeprecatedMain>
              <RowBetween>
                <ResponsiveHeaderText fontSize="24px">
                  <Trans>{percentForSlider}%</Trans>
                </ResponsiveHeaderText>
                <AutoRow gap="4px" justify="flex-end">
                  <SmallMaxButton onClick={() => onPercentSelect(25)} width="20%">
                    <Trans>25%</Trans>
                  </SmallMaxButton>
                  <SmallMaxButton onClick={() => onPercentSelect(50)} width="20%">
                    <Trans>50%</Trans>
                  </SmallMaxButton>
                  <SmallMaxButton onClick={() => onPercentSelect(75)} width="20%">
                    <Trans>75%</Trans>
                  </SmallMaxButton>
                  <SmallMaxButton onClick={() => onPercentSelect(100)} width="20%">
                    <Trans>Max</Trans>
                  </SmallMaxButton>
                </AutoRow>
              </RowBetween>
              <Slider value={percentForSlider} onChange={onPercentSelectForSlider} />
            </AutoColumn>
          </AmountBox>
          {showCollectAsWeth && (
            <RowBetween mt="8px">
              <ThemedText.DeprecatedMain>
                <Trans>Collect as {nativeWrappedSymbol}</Trans>
              </ThemedText.DeprecatedMain>
              <Toggle
                id="receive-as-weth"
                isActive={receiveWETH}
                toggle={() => setReceiveWETH((receiveWETH) => !receiveWETH)}
              />
            </RowBetween>
          )}

          <Box style={{ display: 'flex', width: '100%' }} mt="16px">
            <AutoColumn gap="md" style={{ flex: '1' }}>
              <RemoveBtn
                confirmed={false}
                backgroundColor="red"
                disabled={removed || percent === 0 || !liquidityValue0}
                onClick={() => {
                  setshowRemovePool && setshowRemovePool(modalHeader)
                }}
              >
                {removed ? <Trans>Closed</Trans> : error ?? <Trans>Remove</Trans>}
              </RemoveBtn>
            </AutoColumn>
          </Box>
        </Column>
      </RowBetween>
    </RemovePoolBox>
  )
}
